.carousel-inner{
  height: 90vh !important;
}
.img-slider{
  height: calc(100vh - 0px);
  width: 100%;
}
.carousel-image{
  height: calc(100vh - 0px);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: rgb(255, 255, 255);
  position: relative;
}
.carousel-image::before{
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.carousel-image .card-img-top{
  width: 100% !important;
  height: 100vh;
  object-fit: cover;
}
span.lazy-load-image-loaded{
  display: revert !important;
}
.carousel-image .fw-bold,.jawatha_logo{
  position: absolute;
  left: 50px;
  top: 80px;
}

/* .bg-img-1{
  background-image: url("https://ucarecdn.com/faeef5da-38e0-4cd0-b5d7-6c9dba20e042/");
  background-image: url("https://ucarecdn.com/faeef5da-38e0-4cd0-b5d7-6c9dba20e042/landing1.webp");
}
.bg-img-2{
  background-image: url(https://ucarecdn.com/9e79d0e3-dd34-4a3b-a910-6ac0b229b907/);
}
.bg-img-3{
  background-image: url(https://ucarecdn.com/56160cb3-9840-4dd6-97bb-f0b65c11e1e0/);
} */

/* 
.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgba(0, 0, 0, 0.834);
  z-index: 1;
}

.spinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #3d5af1 transparent #3d5af1 transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

 */
