.call-to-action {
  background: linear-gradient(rgba(14, 29, 52, 0.6), rgba(14, 29, 52, 0.8)), url("https://images.pexels.com/photos/887751/pexels-photo-887751.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1") center center;
  background-size: cover;
  padding: 100px 0;
}

@media (min-width: 1365px) {
  .call-to-action {
    background-attachment: fixed;
  }
}

.call-to-action h3 {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
}
.call-to-action p {
  color: #fff;
}
.call-to-action .cta-btn {
  font-family: var(--font-primary);
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 40px;
  border-radius: 5px;
  transition: 0.5s;
  margin: 10px;
  border: 2px solid #fff;
  color: #fff;
}
.call-to-action .cta-btn:hover {
  background: var(--color-primary);
  border: 2px solid var(--color-primary);
}
.call-to-action{
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.call-to-action .social-links {
  display: flex;
  justify-content: center;
  align-items: center !important;
}

.social-links a {
  width: 80px;
  height: 80px;
  text-align: center;
  text-decoration: none;
  color: #fff;
  box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.05);
  margin: 0 30px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  transition: transform 0.5s;
}

.social-links a .fab, a .fa-location-dot{
  font-size: 30px;
  line-height: 80px;
  position: relative;
  z-index: 10;
  transition: color 0.5s;
}

.social-links a::after {
  content: '';
  width: 100%;
  height: 100%;
  top: -90px;
  left: 0;
  background: #000;
  background: linear-gradient(-150deg, rgba(125,188,67,1) 25%, rgba(227,162,37,1) 50%, rgba(61,170,226,1) 75%);
  position: absolute;
  transition: 0.5s;
}

.social-links a:hover::after {
  top: 0;
}

.social-links a:hover .fab, .fas {
  color: #fff;
}

.social-links a:hover {
  transform: translateY(-10px);
}

