

.img-container {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.card{
  height: 290px !important;
  overflow: hidden;
}
.store-img {
  transition: all 0.8s ease-in-out !important;
  border: 3px solid yellow;
  /* height: 100% !important; */
  /* overflow: hidden; */
}
.img-container:hover .store-img {
  transform: scale(1.1) !important;
}
#store-items{
  max-height: 620px;
  overflow-y: auto;
  border: 10px solid #F90;
  border-left: none;
}

